import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import {genderArr} from "../../cooperate/util/AierCommon";
import {BoneDetailDataType} from "../common/BoneCommonType";
import {BoneSourceObj, BoneStatusObj} from "../common/BoneDictData";

type PatientInfoType = {
    userDetail: BoneDetailDataType["data"]['detailVo']
}
const ServiceUser: React.FC<PatientInfoType> = (props) => {

    return (
        <>
            {(props && props.userDetail) && (
                <Card size="small"
                      title={`个人信息`}
                      extra={<div>
                          患者状态: {BoneStatusObj[props.userDetail.boneStatus]}
                      </div>
                      }>
                    <Space direction="vertical">
                        <>
                            <Descriptions>
                                <Descriptions.Item label="姓名">
                                    {props.userDetail.name}
                                </Descriptions.Item>
                                <Descriptions.Item label="性别">
                                    {genderArr[props.userDetail.gender]}
                                </Descriptions.Item>
                                <Descriptions.Item label="年龄">
                                    {props.userDetail.age}
                                </Descriptions.Item>
                                <Descriptions.Item label="出生日期">
                                    {props.userDetail.dateBirth}
                                </Descriptions.Item>
                                <Descriptions.Item label="身份证号">
                                    {props.userDetail.idNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="关联医院">
                                    {props.userDetail.hospitalName}
                                </Descriptions.Item>
                                <Descriptions.Item label="联系电话">
                                    {props.userDetail.phone}
                                </Descriptions.Item>
                                {/*<Descriptions.Item label="微信号">*/}
                                {/*    {props.userDetail.wxAccount}*/}
                                {/*</Descriptions.Item>*/}
                                <Descriptions.Item label="社区">
                                    {props.userDetail.community}
                                </Descriptions.Item>
                                <Descriptions.Item label="病情描述">
                                    {props.userDetail.illnessMsg}
                                </Descriptions.Item>
                                {props.userDetail.toneSourceId && (
                                    <Descriptions.Item label="如何得知">
                                        {BoneSourceObj[props.userDetail.toneSourceId]}
                                    </Descriptions.Item>
                                )}
                                {props.userDetail.toneOtherSource && (
                                    <Descriptions.Item label="其他方式">
                                        {props.userDetail.toneOtherSource}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </>
                    </Space>
                </Card>
            )}
        </>
    );
};

export default ServiceUser;
