
export type BoneRoleType = {
    //患者名单详情
    ROLE_207001?: number | undefined,
    //缴费确认
    ROLE_207002?: number | undefined,
    //提交审核
    ROLE_207003?: number | undefined,
    //撤回
    ROLE_207004?: number | undefined,
    //筛查
    ROLE_207005?: number | undefined,
    //报销名单详情
    ROLE_208001?: number | undefined,
    //报销审核
    ROLE_208002?: number | undefined,
}

export const BoneStatusObj = {

    1: '待缴费',
    101: '已缴费',
    103: '等待医院申请报销',
    104: '报销申请已撤回',
    105: '报销申请已提交',
    106: '报销审核通过',
    107: '报销审核不通过',
}

export const BoneStatusColorObj = {

    1: 'black',
    101: 'black',
    103: 'black',
    104: 'black',
    105: 'black',
    106: 'black',
    107: 'black',
}
export const BoneStatusSelectArr = [
    {
        value: 1,
        label: '待缴费',
    },
    {
        value: 101,
        label: '已缴费',
    },
    {
        value: 103,
        label: '等待医院申请报销',
    },
    {
        value: 104,
        label: '报销申请已撤回',
    },
    {
        value: 105,
        label: '报销申请已提交',
    },
    {
        value: 106,
        label: '报销审核通过',
    },
    {
        value: 107,
        label: '报销审核不通过',
    }]

export const BoneSourceObj = {

    '01': '社区宣传',
    '02': '水机广告',
    '03': '医生介绍',
    '04': '保险推荐',
    '05': '其他',
}

export const BoneSourceArr = [
    {
        value: '01',
        label: '社区宣传',
    },
    {
        value: '02',
        label: '水机广告',
    },
    {
        value: '03',
        label: '医生介绍',
    },
    {
        value: '04',
        label: '保险推荐',
    },
    {
        value: '05',
        label: '其他',
    }]